/* Fonts */

/* Oxygen Light */
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 300;
  src: local('Oxygen'), local('Oxygen-Light'),
    url('fonts/oxygen/Oxygen-Light.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/oxygen/Oxygen-Light.ttf')
      format('truetype');
}

/* Oxygen Regular */
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  src: local('Oxygen'), local('Oxygen-Regular'),
    url('fonts/oxygen/Oxygen-Regular.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/oxygen/Oxygen-Regular.ttf')
      format('truetype');
}

/* Oxygen Bold */
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  src: local('Oxygen'), local('Oxygen-Bold'),
    url('fonts/oxygen/Oxygen-Bold.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/oxygen/Oxygen-Bold.ttf') format('truetype');
}

/* Segoe UI Regular */
@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: local('Segoe UI'),
    url('fonts/segoe-ui/Segoe UI.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/segoe-ui/Segoe UI.ttf') format('truetype');
}

/* Segoe UI Regular Italic */
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 400;
  src: local('Segoe UI'), local('Segoe UI Italic'),
    url('fonts/segoe-ui/Segoe UI Italic.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/segoe-ui/Segoe UI Italic.ttf')
      format('truetype');
}

/* Segoe UI Bold */
@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: local('Segoe UI'), local('Segoe UI Bold'),
    url('fonts/segoe-ui/Segoe UI Bold.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/segoe-ui/Segoe UI Bold.ttf')
      format('truetype');
}

/* Segoe UI Bold Italic */
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 700;
  src: local('Segoe UI'), local('Segoe UI Bold Italic'),
    url('fonts/segoe-ui/Segoe UI Bold Italic.ttf') format('embedded-opentype'),
    /* Modern Browsers */ url('fonts/segoe-ui/Segoe UI Bold Italic.ttf')
      format('truetype');
}

/* Basics */
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  color: black;
  font-size: calc(10px + 0.390625vw);
  font-family: 'Segoe UI', sans-serif;
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  font-size: 16px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

h2,
h3 {
  font-weight: normal;
}

h1 {
  color: #0069b4;
  font-size: 48px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}
h2 {
  color: #0069b4;
  font-size: 24px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}
h3 {
  color: #0069b4;
  font-size: 18px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}
h4 {
  color: #0069b4;
  font-size: 16px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}
h5 {
  color: #0069b4;
  font-size: 16px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
}
h6 {
  color: #0069b4;
  font-size: 14px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
}

.title-text {
  color: #0069b4;
  font-family: 'Oxygen', sans-serif;
  width: 100%;
  margin-top: 150px;
}
.subtitle-text {
  width: 100%;
  padding: 0px;
  margin: 30px 0px;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #0069b4;
  cursor: pointer;
}
a,
a:link,
a:visited {
  text-decoration: underline;
}
a:hover,
a:active {
  text-decoration: none;
}

p {
  margin: 0 0 1.4em 0;
}

select {
  font-size: 16px;
  width: 40%;
  padding: 4px;
}

input {
}
input.single-line {
  border: 0;
  border-bottom: 1px solid #d3d2c9;
}

.float-right {
  float: right;
}

.margin-top {
  margin-top: 1em;
}
.margin-bottom {
  margin-bottom: 1em;
}

.full-width {
  width: 100%;
}

.title-case {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-light {
  color: #fff;
}
.text-primary {
  color: #ef7d00;
}
.text-secondary {
  color: #0069b4;
}
.text-margin {
  margin-top: -30px;
}
.text-spacing {
  margin-top: 20px;
}
.text-bold {
  font-weight: bold;
}
.text-dashboard {
  font-size: 10px;
}
.miles-title-text {
  position: absolute;
  top: 0;
  left: 65%;
}
.miles-text {
  position: absolute;
  top: 12.75%;
  left: 65%;
}

.bar-text {
  color: white;
  font-family: 'Oxygen', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}
.bar-right-text {
  color: white;
  font-family: 'Oxygen', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
  text-transform: uppercase;
}

/* Layout */
main {
  flex: 9;
}
.banner-plane {
  background: url('./assets/banner-airplane-clouds.jpg') 50% 0% no-repeat;
}
.banner-blue {
  background: url('./assets/header-alternate.png') 50% 0 no-repeat;
  background-size: 100% 330px;
}

.bubble-1 {
  left: -40px;
  bottom: 200px;
  width: 110px;
}
.bubble-2 {
  left: 68px;
  bottom: 160px;
  width: 29px;
}
.bubble-3 {
  right: 45px;
  top: 130px;
  width: 45px;
}
.bubble-4 {
  right: 10px;
  top: 80px;
  width: 29px;
}
.bubble-5 {
  left: -240px;
  top: 425px;
  width: 110px;
}
.bubble-6 {
  left: -120px;
  top: 508px;
  width: 29px;
}
.bubble-7 {
  right: -240px;
  top: 380px;
  width: 154px;
}
.bubble-8 {
  right: -100px;
  top: 347px;
  width: 29px;
}
.bubble-9 {
  right: -40px;
  top: 118px;
  width: 110px;
}
.bubble-10 {
  right: 60px;
  top: 92px;
  width: 29px;
}

.main-footer-light {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0;
}
.main-footer-dark {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  background: url('./assets/footer-standard.png') 50% 100% no-repeat;
  background-size: 100% 557px;
}

.header .btn-background-light {
  color: #ef7d00;
  border: 1px solid #ef7d00;
  background: #fff;
}
.header .btn-background-dark {
  color: #ef7d00;
  border: 1px solid #fff;
  background: #fff;
}

.header {
  margin: 0 auto 45px;
  padding: 13px;
  max-width: 1060px;
  height: 54px;
}
.header menu {
  float: left;
  margin: 0 0 0 40px;
  padding: 0;
  font-size: 12px;
}
.header menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header menu ul li {
  float: left;
}
.header menu ul li a:link,
.header menu ul li a:visited {
  float: left;
  padding: 0 12px;
  height: 28px;
  text-decoration: none;
  line-height: 28px;
  color: #0069b4;
}
.header.header-dark menu ul li a:link,
.header.header-dark menu ul li a:visited {
  color: #fff;
}
.header menu ul li a:hover,
.header menu ul li a:active {
  text-decoration: underline;
}
.dialog-login {
  display: none;
  position: absolute;
  top: 47px;
  right: 100px;
  padding: 20px;
  width: 374px;
  line-height: 1.5em;
  color: #222;
  border: 1px solid #d3d2c9;
  border-radius: 7px;
  background: #fff;
  z-index: 10000;
}
.header.start-login .dialog-login {
  display: block;
}
.dialog-login .dialog-close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}
.dialog-login h3 {
  font-size: 1.75em;
  line-height: 1.5em;
}
.dialog-login p {
  margin-top: 3px;
}
.dialog-login .input-holder {
  margin: 12px 0 10px 0;
}
.dialog-login input[type='text'],
.dialog-login input[type='password'] {
  padding: 3px;
  width: 100%;
  font-size: 1.25em;
}
.dialog-login .qr-holder {
  text-align: center;
  border: 1px solid #d3d2c9;
}
.dialog-login .qr-holder h3 {
  margin-top: 6px;
  margin-bottom: 0;
}
.overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.22;
  background-color: #000;
  z-index: 1000;
}
.header.start-login .overlay {
  display: block;
}

.footer {
  position: relative;
  font-size: calc(10px + 0.390625vw);
  padding: 20px 0;
  height: calc(60px + 0.390625vw);
}

.footer-dark {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 60px;
  width: 100%;
}
@media (min-width: 540px) {
  .footer-dark {
  }
}

.one-third-column {
  float: left;
  width: 33.33%;
  color: red;
}

.two-third-column {
  float: left;
  width: 66.66%;
  color: yellow;
}

@media (max-width: 720px) {
  .one-third-column {
    float: none;
    width: 100%;
  }

  .two-third-column {
    float: none;
    width: 100%;
  }
}

.banner-row {
  position: relative;
  margin: 0 auto;
  padding: 20px 13px;
  max-width: 1060px;
}
.banner-plane .banner-row,
.banner-blue .banner-row {
  color: #fff;
}
.banner-plane .banner-row h1 {
  margin-bottom: 0.2em;
  color: #fff;
}
.banner-plane .banner-row h2 {
  margin-bottom: 1em;
  color: #fff;
}
.banner-blue .dashboard-title-row h1,
.banner-blue .dashboard-title-row h3 {
  margin-bottom: 0;
  color: #fff;
}

.content-row {
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 13px;
  max-width: 1060px;
}

.work-box {
  margin: 120px auto 2em;
  max-width: 1033px;
  min-height: 294px;
  box-shadow: 0 0 2px 1px rgba(38, 38, 38, 0.2);
  border-radius: 7px;
  background: #fff;
}
.work-box .header-row {
  overflow: hidden;
  padding: 12px 25px;
  min-height: 49px;
  border-bottom: 1px solid #222;
}
.work-box .work-option {
  margin-right: 40px;
}
.work-box .selectors {
  float: right;
}
.work-box .options-row .filters {
  overflow: hidden;
  padding: 12px 25px;
  min-height: 49px;
  font-size: 12px;
}
.work-box .options-row .filter {
  margin-right: 25px;
}
.work-box .options-row .advanced-search {
  float: right;
  font-size: 12px;
}
.work-box .options-row .locations,
.work-box .options-row .dates,
.work-box .options-row .details {
  padding: 0 25px;
  overflow: hidden;
}
.work-box .options-row .input-wrapper {
  float: left;
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  height: 44px;
  border: 1px solid #d3d2c9;
  border-radius: 22px;
}
.work-box .options-row .input-wrapper.narrow {
  width: 100%;
}
@media (min-width: 720px) {
  .work-box .options-row .input-wrapper {
    width: 44%;
  }
  .work-box .options-row .input-wrapper.narrow {
    margin-right: 25px;
    width: 20%;
  }
}
.work-box .options-row .input-wrapper label {
  margin: 12px 0;
  padding: 0 12px 0 18px;
  font-size: 14px;
  color: #0069b4;
  border-right: 1px solid #d3d2c9;
}
.work-box .options-row .input-wrapper input {
  flex-grow: 1;
  padding: 11px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  border: none;
  background: none;
}
.articles-box {
  margin: 0 auto 2em;
  max-width: 1033px;
  min-height: 240px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 32px;
  row-gap: 25px;
}
.article-primary {
  padding: 20px;
  text-align: right;
  color: #fff;
  box-shadow: 0 0 2px 1px rgba(38, 38, 38, 0.2);
  border-radius: 7px;

  background: url('./assets/article-travel-with-app.jpg') 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.article-primary h2 {
  font-size: 36px;
  line-height: 1.15em;
  color: #fff;
  text-shadow: 2px 2px rgba(38, 38, 38, 0.49);
}
.article-primary p {
  padding-left: 45%;
  text-shadow: 2px 2px rgba(38, 38, 38, 0.49);
}
.article-secondary {
  padding: 25px;
  box-shadow: 0 0 2px 1px rgba(38, 38, 38, 0.2);
  border-radius: 7px;
  background: #f6f6f6;
}
.article-secondary h2 {
  font-size: 20px;
}
@media (min-width: 720px) {
  .articles-box {
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 1fr 1fr;
  }
  .article-primary {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}

/* Account Dashboard */
.dashboard-title-row {
  margin: 0 auto;
  padding: 0 13px;
  max-width: 1060px;
  overflow: hidden;
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
}
.dashboard-left-title {
  float: left;
  width: 65%;
}
@media (max-width: 1200px) {
  .dashboard-left-title {
    padding-left: 60px;
  }
}
.dashboard-right-title {
  position: relative;
  float: left;
  width: 35%;
}

.dashboard-row {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 50px;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 13px;
  max-width: 1060px;
  width: 100%;
}
@media (max-width: 720px) {
  .dashboard-row {
    flex-direction: column;
  }
}

.visa-wrapper {
  position: absolute;
  background: linear-gradient(90deg, #0176c0 0%, transparent 100%);
  display: flex;
  flex-direction: row;
  padding: 10px;
  z-index: 100;
  text-transform: none;
  width: 33%;
  align-items: left;
  font-weight: bold;
}

.trips-dashboard {
  flex-grow: 1;
  height: 80%;
}
.account-header {
  color: black;
  font-size: calc(24px + 0.390625vw);
  font-family: 'Oxygen', sans-serif;
  width: 100%;
  font-weight: 700;
  margin-bottom: 15px;
}
.trips-banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 18px;
  height: 160px;
  font-family: 'Oxygen', sans-serif;
  font-size: 1.5vmin;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  border-radius: 7px;
  background: linear-gradient(90deg, #0069b4 0%, #008bd2 100%);
}
.trips-text {
  font-size: 10px;
}

/* absolute placement code for the account dashboard trips */
.trips-image {
  position: absolute;
  top: 50%;
  right: 62%;
  margin-top: -160px;
  width: 320px;
  height: 320px;
  border-radius: 100%;
  background-color: #ef7d00;
  background-size: cover;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.trips-image:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    90deg,
    rgba(43, 45, 84, 0.8) 13%,
    rgba(43, 45, 84, 0) 100%
  );
  z-index: 100;
}
.trips-code-entry {
  position: absolute;
  top: 105px;
  left: 1.5%;
  z-index: 101;
}
.trips-city-entry {
  position: absolute;
  top: 123px;
  left: 1.5%;
  z-index: 101;
}
.trips-date {
  position: absolute;
  top: 22px;
  left: 43%;
}
.trips-date-entry {
  position: absolute;
  top: 18px;
  left: 48%;
}
.trips-boarding {
  position: absolute;
  top: 22px;
  left: 60%;
}
.trips-boarding-entry {
  position: absolute;
  top: 18px;
  left: 69%;
}
.trips-seat {
  position: absolute;
  top: 22px;
  left: 81%;
}
.trips-seat-entry {
  position: absolute;
  top: 19px;
  left: 86%;
}
.trips-flight {
  position: absolute;
  top: 54px;
  left: 43%;
}
.trips-flight-entry {
  position: absolute;
  top: 51.5px;
  left: 49.5%;
}
.trips-departing {
  position: absolute;
  top: 54px;
  left: 60%;
}
.trips-departing-entry {
  position: absolute;
  top: 51.5px;
  left: 69.5%;
}
.trips-class {
  position: absolute;
  top: 54px;
  left: 81%;
}
.trips-class-entry {
  position: absolute;
  top: 51.5px;
  left: 87.5%;
}
.trips-banner .boarding-btn {
  position: absolute;
  top: 87px;
  left: 43%;
  padding: 0 12px;
  font-size: 10px;
}
.trips-banner .edit-btn {
  position: absolute;
  top: 87px;
  left: 66%;
  padding: 0 12px;
  font-size: 10px;
}
.trips-banner .cancel-btn {
  position: absolute;
  top: 87px;
  left: 80%;
  padding: 0 12px;
  font-size: 10px;
}
.seamless {
  position: absolute;
  top: 127px;
  left: 43%;
  text-transform: none;
}
.add-trips {
  position: relative;
  margin-bottom: 25px;
  height: 85px;
  text-align: center;
  border-radius: 7px;
  background: #edece5;
}
.add-trips .icon {
  font-size: 40px;
  line-height: 85px;
  color: #999;
}

.left-nav-menu {
  position: relative;
  float: left;
  width: 25%;
  height: 80%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 720px) {
  .left-nav-menu {
    width: 100%;
  }
}
.left-nav-menu h2 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.toggle-arrow {
  float: right;
  color: #0069b4;
  font-size: 12px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}
.left-nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.left-nav-menu ul li {
}
.left-nav-menu ul li a,
.left-nav-menu ul li a:link,
.left-nav-menu ul li a:visited {
  display: block;
  padding: 6px 0;
  color: #000;
  font-size: 14px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
  text-decoration: none;
}
.left-nav-menu ul li a:hover,
.left-nav-menu ul li a:active {
  text-decoration: underline;
}
.horizontal-line {
  margin: 21px 0 25px 0;
  border-top: 1px solid #d3d2c9;
}

.account-row {
  margin: 0 auto;
  padding: 0 13px;
  max-width: 540px;
  overflow: hidden;
}

/* .row:after {
  content: '';
  display: table;
  clear: both;
} */

/* Boarding Pass Review Screen */
.boarding-pass-gold {
  position: relative;
  margin: 30px 0;
  height: 330px;
  background: linear-gradient(0deg, #b38c28 -2.42%, #fff85c 123.79%);
  font-family: 'Oxygen', sans-serif;
  color: #fff;
  width: 94vw;
  max-width: 757px;
  font-size: 1.5vmin;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 7px;
}
.boarding-pass-silver {
  position: relative;
  margin: 30px 0;
  height: 330px;
  background: linear-gradient(0deg, #8693ab -2.42%, #bdd4e7 123.79%);
  font-family: 'Oxygen', sans-serif;
  color: #fff;
  width: 94vw;
  max-width: 757px;
  font-size: 1.5vmin;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 7px;
}
.boarding-pass-bronze {
  position: relative;
  margin: 30px 0;
  height: 330px;
  background: linear-gradient(0deg, #a44200 -2.42%, #f2c17d 123.79%);
  font-family: 'Oxygen', sans-serif;
  color: #fff;
  width: 94vw;
  max-width: 757px;
  font-size: 1.5vmin;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 7px;
}
.chip-photo {
  position: absolute;
  top: 25%;
  left: 3%;
  width: 23%;
  object-fit: cover;
}
.boarding-pass-logo {
  position: absolute;
  top: 8%;
  left: 3%;
  background: url('./assets/logo-white.png') no-repeat;
  background-size: 100%;
  width: 23%;
  padding: 0 0 100px;
}
.divider-line {
  border-left: #fff solid thin;
  position: absolute;
  top: 8%;
  left: 29%;
  height: 86%;
}
.plane-icon {
  position: absolute;
  top: 34%;
  left: 60%;
  background: url('./assets/plane.png') no-repeat;
  width: 100%;
  background-size: 5.5%;
  height: 100%;
}
.pass-label {
  font-family: 'Oxygen', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.pass-data {
  font-family: 'Oxygen', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.passenger-image {
  position: absolute;
  top: 73px;
  left: 25px;
  width: 23%;
  border-radius: 7px;
}
.gate {
  position: absolute;
  top: 8%;
  left: 33%;
}
.gate-text {
  position: absolute;
  top: 13%;
  left: 33%;
}
.passenger {
  position: absolute;
  top: 8%;
  left: 45%;
}
.passenger-text {
  position: absolute;
  top: 13%;
  left: 45%;
}
.departure-city {
  position: absolute;
  top: 48%;
  left: 33%;
}
.departure-city-code {
  position: absolute;
  top: 26%;
  left: 32%;

  font-family: 'Oxygen', sans-serif;
  font-size: 64px;
  color: #fff;
}
.arrival-city {
  position: absolute;
  top: 48%;
  left: 75%;
}
.arrival-city-code {
  position: absolute;
  top: 26%;
  left: 74%;

  font-family: 'Oxygen', sans-serif;
  font-size: 64px;
  color: #fff;
}
.departure-date {
  position: absolute;
  left: 33%;
  top: 60%;
}
.departure-date-text {
  position: absolute;
  left: 33%;
  top: 65%;
}
.boarding-time {
  position: absolute;
  top: 60%;
  left: 60%;
}
.boarding-time-text {
  position: absolute;
  top: 65%;
  left: 60%;
}
.departing-time {
  position: absolute;
  top: 60%;
  left: 80%;
}
.departing-time-text {
  position: absolute;
  top: 65%;
  left: 80%;
}
.flight-number {
  position: absolute;
  left: 33%;
  top: 80%;
}
.flight-number-text {
  position: absolute;
  left: 33%;
  top: 85%;
}
.seat-number {
  position: absolute;
  top: 80%;
  left: 47%;
}
.seat-number-text {
  position: absolute;
  top: 85%;
  left: 47%;
}
.terminal {
  position: absolute;
  top: 80%;
  left: 58%;
}
.terminal-text {
  position: absolute;
  top: 85%;
  left: 62%;
}
.zone {
  position: absolute;
  top: 80%;
  left: 73%;
}
.zone-text {
  position: absolute;
  top: 85%;
  left: 75%;
}
.status {
  position: absolute;
  top: 80%;
  left: 85%;
}
.status-text {
  position: absolute;
  top: 85%;
  left: 85%;
}

/* Loading Spinners */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 150px 0;
  width: 100%;
  align-items: center;
}
.loader {
  display: inline-block;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  margin: 0 auto 40px;
  width: 30%;
  border: 17px solid #0069b4;
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dialog-login .loader-wrapper {
  padding: 175px 0;
  text-align: center;
}
.dialog-login .loader {
  width: 50%;
}

.loader-wrapper-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  width: 60%;
  margin-left: 40%;
  align-items: center;
}
.loader-dashboard {
  display: inline-block;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  margin: 0 auto 40px;
  width: 15%;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
